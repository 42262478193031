import React, { useEffect, useState } from 'react'

import clsx from 'clsx'

import { isMobile } from 'react-device-detect'

import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'

import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'

import DatePicker from 'react-datepicker'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Chip from '@material-ui/core/Chip'
import DateView from '../../../components/DateView'
import { useFilterStyles } from '../../../hooks/useFilter'
import { useCurrencies } from '../../../api/private/currency'
import { OrderStatus } from '../../../api/order'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import DebounceInput from '../../../components/Form/DebounceInput'
import ordersListConfig from '@/config/ordersList.json'
import { WIIDGET_ENV } from '@/helpers/constants'
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'
import { useMerchantAPMList, useMerchantSidsList } from '@/api/private/orders'
import addDays from 'date-fns/addDays'

import 'react-datepicker/dist/react-datepicker.css'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectRow: {
    padding: '20px 0px 0px',
  },
}))

export const ORDER_TYPES = [
  { value: 'FIAT_TO_CRYPTO', name: 'Deposit' },
  { value: 'WALLET_TO_FIAT', name: 'Payouts' },
  { value: 'INVOICE', name: 'Invoice' },
]

export function getLabel(type: string) {
  const findActiveType = ORDER_TYPES.find((item) => item.value === type)
  if (findActiveType) {
    return findActiveType.name
  }
}

export interface IFilter {
  dateFrom: number
  dateTo: number
  paymentCurrency?: string[] | null
  currency?: string[] | null
  status?: string[] | null
  paymentMethod?: string | null
  orderTypes?: string[] | null
}

interface IAPM {
  id: number
  createdAt?: number
  updatedAt?: number
  name: string
  enabled?: boolean
  externalId: string
}

interface IProps {
  filter: IFilter
  onChange: any
  onClear: any
  onSubmit: any
  type: 'customer' | 'merchant' | 'referral'
  load: any
  className?: string
  searchField?: boolean
}

interface IDatesChange {
  dateFrom: number | null
  dateTo: number | null
  isCustom?: boolean
}

export default function OrdersFilter({
  filter,
  onChange,
  onSubmit,
  onClear,
  type,
  load,
  className,
  searchField,
}: IProps) {
  const classes = useFilterStyles()
  const globalClasses = useGlobalStyles()
  const today = new Date()
  const todayStart = startOfDay(today).valueOf()
  const todayEnd = endOfDay(today).valueOf()
  const yesterdayStart = startOfDay(subDays(today, 1)).valueOf()
  const yesterdayEnd = endOfDay(subDays(today, 1)).valueOf()
  const weekStart = startOfDay(subWeeks(today, 1)).valueOf()
  const monthStart = startOfDay(subMonths(today, 1)).valueOf()
  const { user } = useSelector((state: any) => state.account)
  const [open, setOpen] = useState(false)
  const [dateChanged, setDateChanged] = useState(false)
  const [startDate, setStartDate] = useState(new Date(monthStart))
  const [endDate, setEndDate] = useState<Date | null>(new Date(filter.dateTo))
  const [datePicker, setDatePicker] = useState(false)
  const [customDates, setCustomDates] = useState(false)
  const [{ data: currencies }, currenciesLoading, loadCurrencies] =
    useCurrencies()
  const [searchCustomerAndEmailError, setSearchCustomerAndEmailError] =
    useState(false)
  const [searchOrderError, setSearchOrderError] = useState(false)
  const { t } = useTranslation()
  const classesSelect = useStyles()
  const [{ items: sids }, loadingSids, loadSids] = useMerchantSidsList()
  const [{ items: apms }, loadingAPMList, loadAPMList] = useMerchantAPMList()
  const [selectedSid, setSelectedSid] = useState('All')

   // @ts-ignore
   const showEmail = !ordersListConfig.hideEmailForMerchants[WIIDGET_ENV]?.includes(String(user.id))

  const activeFilters: any = {}
  Object.keys(filter).forEach((f) => {
    // @ts-ignore
    if (!['dateFrom', 'dateTo'].includes(f) && !!filter[f]) {
      // @ts-ignore
      activeFilters[f] = filter[f]
    }
  })
  const active = Object.keys(activeFilters).length > 0 || dateChanged

  function changeDates(
    { dateFrom, dateTo, isCustom }: IDatesChange,
    reload?: boolean
  ) {
    if (dateFrom === null) {
      onChange(
        {
          dateFrom: startOfDay(subDays(new Date(), 6)).valueOf(),
          dateTo: todayEnd,
        },
        reload ? load : undefined
      )
    } else {
      onChange({ dateFrom, dateTo })
    }
    setDateChanged(!!dateFrom)
    setCustomDates(!!isCustom)
  }

  function changeFilter(c: string, name: string, reload?: boolean) {
    // @ts-ignore
    const f = Array.isArray(filter[name]) ? [...filter[name]] : []
    const existI = f.indexOf(c)
    if (existI !== -1) {
      f.splice(existI, 1)
    } else {
      f.push(c)
    }
    onChange({ [name]: f }, reload ? load : undefined)
  }

  useEffect(() => {
    loadCurrencies()
    loadSids()
    loadAPMList()
  }, [])

  useEffect(() => {
    setStartDate(new Date(filter.dateFrom))
    setEndDate(new Date(filter.dateTo))
  }, [filter.dateFrom, filter.dateTo])

  const currencyFilter = type === 'referral' ? 'currency' : 'paymentCurrency'

  const changeExternalIdFilter = (serchFieldValue: any) => {
    let filter = {}
    if (serchFieldValue === '') {
      filter = {
        externalId: null,
      }
    } else {
      filter = {
        externalId: serchFieldValue,
      }
    }

    onChange(filter, load)
  }
  const changeOrderIdFilter = (serchFieldValue: any) => {
    let filter = {}
    if (serchFieldValue === '') {
      filter = {
        orderId: null,
      }
    } else if (!isNaN(parseInt(serchFieldValue))) {
      filter = {
        orderId: serchFieldValue,
      }
    } else {
      setSearchOrderError(true)
      return
    }

    setSearchOrderError(false)
    onChange(filter, load)
  }

  const changeCustomerAndEmailFilter = (serchFieldValue: any) => {
    let filter = {}
    if (serchFieldValue === '') {
      filter = {
        acceptedById: null,
        acceptedBy: null,
      }
    } else if (!isNaN(parseInt(serchFieldValue))) {
      filter = {
        acceptedById: serchFieldValue,
        acceptedBy: null,
      }
    } else if (/^[^\s@]+@[^\s@]+$/.test(serchFieldValue)) {
      filter = {
        acceptedById: null,
        acceptedBy: serchFieldValue,
      }
    } else {
      setSearchCustomerAndEmailError(true)
      return
    }

    setSearchCustomerAndEmailError(false)
    onChange(filter, load)
  }

  const handleChangeSid = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedSid(event.target.value as string)
    if (event.target.value === 'All') {
      onChange({ sid: null })
    } else {
      onChange({ sid: event.target.value })
    }
  }

  const bankCard = {
    id: Math.random(),
    externalId: 'cc_card',
    name: 'Bank card',
    enabled: true,
  }

  const apmList = [...apms, bankCard]

  function getMaxDate() {
    if (endDate) {
      return today
    } else {
      if (addDays(startDate, 6).valueOf() > today.valueOf()) {
        return today
      }
      return addDays(startDate, 6)
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      {searchField && (
        <>
          <div className={classes.searchFieldWrapper}>
            <DebounceInput
              onChange={(value: any) => changeCustomerAndEmailFilter(value)}
              deley={500}
              fieldError={searchCustomerAndEmailError}
              label={showEmail ? 'Email or client id' : 'Search by client id'}
            />
            <DebounceInput
              onChange={(value: any) => changeExternalIdFilter(value)}
              deley={500}
              label="External id"
            />
            <DebounceInput
              onChange={(value: any) => changeOrderIdFilter(value)}
              deley={500}
              fieldError={searchOrderError}
              label="Order id"
            />
            <div className={classes.wrapperButton}>
              <IconButton
                className={clsx(classes.button, { active }, classes.mobile)}
                onClick={() => setOpen(!open)}
              >
                <FilterListIcon />
              </IconButton>
            </div>
          </div>
        </>
      )}

      {!searchField && (
        <IconButton
          className={clsx(classes.button, { active })}
          onClick={() => setOpen(!open)}
        >
          <FilterListIcon />
        </IconButton>
      )}
      {open ? (
        <div className={classes.filters}>
          <div className={classes.title}>{t('Filter orders')}</div>
          <IconButton className={classes.close} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
          {type === 'merchant' && (
            <div className={classes.filter}>
              <Typography variant="h6">{t('SIDs')}</Typography>
              <div className={classesSelect.selectRow}>
                <FormControl
                  variant="outlined"
                  className={classesSelect.formControl}
                >
                  <InputLabel id="sid-label" variant="filled">
                    {t('SID')}
                  </InputLabel>
                  <Select
                    labelId="sid-label"
                    id="sid-select"
                    variant="filled"
                    value={selectedSid}
                    onChange={handleChangeSid}
                    label="SID"
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    {sids.map((sidItem: { sid: string }) => (
                      <MenuItem key={sidItem.sid} value={sidItem.sid}>
                        {sidItem.sid}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.filter}>
            <Typography variant="h6">{t('Date range')}</Typography>
            <div className={classes.optionsDate}>
              <div className={classes.chips}>
                <Chip
                  label={t('Today')}
                  onClick={() =>
                    changeDates({ dateFrom: todayStart, dateTo: todayEnd })
                  }
                  color={
                    filter.dateFrom === todayStart && filter.dateTo === todayEnd
                      ? 'primary'
                      : 'default'
                  }
                />
                <Chip
                  label={t('Yesterday')}
                  onClick={() =>
                    changeDates({
                      dateFrom: yesterdayStart,
                      dateTo: yesterdayEnd,
                    })
                  }
                  color={
                    filter.dateFrom === yesterdayStart &&
                    filter.dateTo === yesterdayEnd
                      ? 'primary'
                      : 'default'
                  }
                />
                <Chip
                  label={t('Last week')}
                  onClick={() =>
                    changeDates({ dateFrom: weekStart, dateTo: todayEnd })
                  }
                  color={
                    filter.dateFrom === weekStart && filter.dateTo === todayEnd
                      ? 'primary'
                      : 'default'
                  }
                />
              </div>
              <div className={classes.datepicker}>
                <Chip
                  label={
                    customDates && endDate !== null ? (
                      <span>
                        <DateView value={startDate} timeless /> -{' '}
                        <DateView value={endDate} timeless />
                      </span>
                    ) : (
                      t('Custom range')
                    )
                  }
                  onClick={() => {
                    setDatePicker(!datePicker)
                    if (!customDates) {
                      setStartDate(new Date())
                      setEndDate(new Date())
                    }
                  }}
                  color={customDates || datePicker ? 'primary' : 'default'}
                />
                {datePicker && (
                  <ClickAwayListener
                    onClickAway={() => setDatePicker(!datePicker)}
                  >
                    <div className="wrapper">
                      <DatePicker
                        selected={startDate}
                        onChange={(range) => {
                          if (Array.isArray(range)) {
                            const [start, end] = range
                            if (!end) {
                              setStartDate(start)
                              setEndDate(null)
                            } else {
                              changeDates({
                                dateFrom: start.valueOf(),
                                dateTo: end.valueOf(),
                                isCustom: true,
                              })
                              setDatePicker(false)
                            }
                          }
                        }}
                        minDate={new Date(user.createdAt)}
                        maxDate={getMaxDate()}
                        // showDisabledMonthNavigation
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                      />
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          </div>
          <div className={classes.filter}>
            <Typography variant="h6">{t('Currency')}</Typography>
            <div className={classes.options}>
              {currencies
                .filter((c: any) => c.type === 'FIAT')
                .map((c: any) => (
                  <Chip
                    label={c.currency}
                    onClick={() => changeFilter(c.currency, currencyFilter)}
                    // @ts-ignore
                    color={filter[currencyFilter] && filter[currencyFilter].includes(c.currency) ? 'primary' : 'default'}
                    key={c.currency}
                  />
                ))}
            </div>
          </div>
          {type === 'merchant' && (
            <>
              <div className={classes.filter}>
                <Typography variant="h6">{t('Status')}</Typography>
                <div className={classes.options}>
                  {Object.keys(OrderStatus).map((s) => (
                    <Chip
                      label={s}
                      onClick={() => changeFilter(s, 'status')}
                      color={filter.status && filter.status.includes(s) ? 'primary' : 'default'}
                      key={s}
                    />
                  ))}
                </div>
              </div>
              <div className={classes.filter}>
                <Typography variant="h6">{t('Type')}</Typography>
                <div className={classes.options}>
                  {ORDER_TYPES.map((type) => (
                    <Chip
                      label={type.name}
                      onClick={() => changeFilter(type.value, 'orderTypes')}
                      color={
                        filter.orderTypes &&
                        filter.orderTypes.includes(type.value)
                          ? 'primary'
                          : 'default'
                      }
                      key={type.value}
                    />
                  ))}
                </div>
              </div>
              <div className={classes.filter}>
                <Typography variant="h6">{t('Payment Methods')}</Typography>
                <div className={classes.options}>
                  {apmList
                    .filter(
                      (apm: IAPM) =>
                        !apm.externalId.includes('apmgw') &&
                        apm.externalId !== 'skrill_ext' &&
                        apm.enabled
                    )
                    .sort((a: IAPM, b: IAPM) =>
                      a.externalId.localeCompare(b.externalId)
                    )
                    .map((s: IAPM) => (
                      <Chip
                        label={
                          s.externalId === 'cc_card' ? s.name : s.externalId
                        }
                        onClick={() =>
                          changeFilter(s.externalId, 'paymentMethod')
                        }
                        color={
                          filter.paymentMethod &&
                          filter.paymentMethod.includes(s.externalId)
                            ? 'primary'
                            : 'default'
                        }
                        key={s.externalId}
                      />
                    ))}
                </div>
              </div>
            </>
          )}
          <div className={classes.actions}>
            <Button
              onClick={() => {
                onSubmit();
                setOpen(false);
              }}
              variant="contained"
              className={globalClasses.button}
              color="primary"
            >
              {t('Apply')}
            </Button>
            <Button
              onClick={() => {
                onClear();
                setDateChanged(false);
                setCustomDates(false)
              }}
              variant="contained"
              className={globalClasses.button}
            >
              {t('Clear')}
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.viewOptions}>
          {dateChanged && (
            <div className={clsx(classes.options, 'view')}>
              <Chip
                label={
                  <>
                    <DateView value={filter.dateFrom} timeless /> -{' '}
                    <DateView value={filter.dateTo} timeless />
                  </>
                }
                onDelete={() =>
                  changeDates({ dateFrom: null, dateTo: null }, true)
                }
                size={isMobile ? 'medium' : 'small'}
              />
            </div>
          )}
          {activeFilters.paymentCurrency && (
            <div className={clsx(classes.options, 'view')}>
              {activeFilters.paymentCurrency.map((i: string) => (
                <Chip
                  label={i}
                  key={i}
                  onDelete={() => changeFilter(i, 'paymentCurrency', true)}
                  size={isMobile ? 'medium' : 'small'}
                />
              ))}
            </div>
          )}
          {activeFilters.status && (
            <div className={clsx(classes.options, 'view')}>
              {activeFilters.status.map((i: string) => (
                <Chip
                  label={i}
                  key={i}
                  onDelete={() => changeFilter(i, 'status', true)}
                  size={isMobile ? 'medium' : 'small'}
                />
              ))}
            </div>
          )}
          {activeFilters.orderTypes && (
            <div className={clsx(classes.options, 'view')}>
              {activeFilters.orderTypes.map((activeType: string) => (
                <Chip
                  label={getLabel(activeType)}
                  key={activeType}
                  onDelete={() => changeFilter(activeType, 'orderTypes', true)}
                  size={isMobile ? 'medium' : 'small'}
                />
              ))}
            </div>
          )}
          {activeFilters.paymentMethod && (
            <div className={clsx(classes.options, 'view')}>
              {activeFilters.paymentMethod.map((i: string) => (
                <Chip
                  label={i}
                  key={i}
                  onDelete={() => changeFilter(i, 'paymentMethod', true)}
                  size={isMobile ? 'medium' : 'small'}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
