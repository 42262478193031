import { AxiosResponse } from 'axios'
import Api, { PrivateApi } from './index';
import useFetchApi from '../hooks/useFetch';
import useUpdateApi, { useUpdateApiPromise } from '../hooks/useUpdate'
import useFingerprintedRequest from '../hooks/useFingerprint';
import { IPayResponse } from '@/types/payment';
import { useAcceptTerms } from './private/account'

export enum PaymentSource {
  Card = 'CARD',
  CardInternal = 'CARD_INTERNAL',
  APM = 'APM'
}

export enum PaymentNext {
  CardForm = 'CARD_FORM',
  ExternalUrl = 'EXTERNAL_URL',
  ThreeDS = 'THREEDS_FORM',
  ThreeDSV2 = 'THREEDS',
  Fingerprint = 'FINGERPRINT',
  Verification = 'VERIFICATION',
  tokenGeneration = 'TOKEN_GENERATION',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export type initPaymentPayload = {
  source: string
  orderId: string
  apm: {
    id: string
    inputs: { sessionLink: string }
  }
}

export function usePaymentInit(orderId: string, onInit = true) {
  const fingerprintedRequest = useFingerprintedRequest(orderId);
  const [state, sending, send] = useFetchApi(
    async (...data: any) => Api.post('/payment/init', ...data),
    { data: {} },
    undefined,
    onInit,
    ['COUNTRY_NOT_SET', 'GEODATA_NOT_RECOGNIZED']
  );
  return [state, sending, (data: any) => fingerprintedRequest(send, data)];
}

export function useWaterfallPaymentInit(
  orderId: string,
  isTermsAccepted: boolean,
  cb: (res: IPayResponse) => void
): [(data: any) => void, boolean] {
  const [acceptTerms] = useAcceptTerms(() => null)
  const fingerprintedRequest = useFingerprintedRequest(orderId);
  const [send, sending] = useUpdateApi(
    async (...data: any) => PrivateApi.post('/payment/init', ...data),
    cb,
    ['COUNTRY_NOT_SET', 'GEODATA_NOT_RECOGNIZED']
  );
  return [
    (data: any) => {
      if (!isTermsAccepted) {
        acceptTerms({})
      }
      fingerprintedRequest(send, data)
    },
    sending,
  ]
}

export function useWaterfallPaymentInitMobile(
  orderId: string,
  isTermsAccepted: boolean,
  cb: (res: Record<string, any>) => void
): [(data: initPaymentPayload) => Promise<AxiosResponse>, boolean] {
  const [acceptTerms] = useAcceptTerms(() => null)
  const fingerprintedRequest = useFingerprintedRequest(orderId);
  const [send, sending] = useUpdateApiPromise<
    initPaymentPayload,
    AxiosResponse
  >({
    remote: (...data) => {
      return PrivateApi.post('/payment/init', ...data)
    },
    sentryIgnoreErrors: ['COUNTRY_NOT_SET', 'GEODATA_NOT_RECOGNIZED'],
  })

  return [
    async (data: initPaymentPayload) => {
      if (!isTermsAccepted) {
        acceptTerms({})
      }
      const response: any = fingerprintedRequest(send, data)
      await response.then(({ data }: AxiosResponse) => cb(data))
      return response
    },
    sending,
  ]
}

export function usePaymentStatusApi(cb: (res: IPayResponse) => void) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/payment/status', ...data),
    cb,
  )
}
