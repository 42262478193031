import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { PaymentSource } from '../../api/payment';
import { IAPM, IApmFieldValue, IAPMField } from '../../api/settings';
import { Input, AutocompleteInput } from '../../components/Form';
import Loader from '../../components/Loader';
import { composeValidators, required, validateByRegex } from '../../validators';
import AddressAndTerms from './AddressAndTerms';
import OrderDetails from './Details';
import PayButton from './PayButton';
import APMVerifyIcon from '../../icons/apmVerify.svg';
import usePaymentFormStyles from './usePaymentFormStyles';
import { trackClickPay, trackErrorScreen } from '@/helpers/analytics'
import { getApmView } from '@/helpers/getApmView'

export const useApmStyles = makeStyles((theme) => ({
  root: {
  },
  verify: {
    background: `url("${APMVerifyIcon}") no-repeat center ${theme.spacing(3)}px`,
    backgroundColor: 'rgba(28, 84, 211, 0.05)',
    borderRadius: 12,
    padding: `${theme.spacing(3) + 74}px 42px ${theme.spacing(4)}px`,
    '& .title': {
      marginBottom: 10
    }
  },
  waitingForKyc: {
    borderRadius: 12,
    padding: '24px 42px 32px',
    background: 'rgba(242, 201, 76, 0.05)',
    '& .title': {
      marginBottom: 10
    }
  }
}));

// interface IProps extends IPreVerifyCheckProps {
interface IProps {
  apm?: IAPM;
  buttonDisabled: boolean;
  requestInProgress: boolean;
  validateTermsAccept(valid: boolean, focus: boolean): [boolean, boolean];
  validateBillingAddress(valid: boolean): boolean;
  apmAmount?: number;
  setFormData(data: any): void;
  hasGooglePay: boolean;
  checkingKyc: boolean;

  order: any;
  customer: any;
  addressAcceptField: any;
  setConfirmFormChange: any;
  setTermsError: any;
  termsAcceptField: any;
  setTermsAction: any;
  hasApplePay: any;
  termsError: any;
  paymentError?: string
  step: string
}

const obIds = ['apmgw_Instant_Open_Banking', 'apmgw_Open_Banking'];
const obBankIdName = {
  'apmgw_Instant_Open_Banking': 'instantOb_bankId',
  'apmgw_Open_Banking': 'ob_bank_id',
}

export default function APMForm(props: any /*IProps*/) {
  const {
    order,
    step,
    apm = {} as IAPM,
    buttonDisabled,
    paymentError,
    requestInProgress,
    customer,
    addressAcceptField,
    setConfirmFormChange,
    setTermsError,
    termsAcceptField,
    setTermsAction,
    termsError,
    validateTermsAccept,
    validateBillingAddress,
    hasApplePay,
    hasGooglePay,
    checkingKyc,
    apmAmount,
    setFormData,
  } = props;

  const classes = useApmStyles();
  const paymentFormClasses = usePaymentFormStyles();
  const { t } = useTranslation();
  const [bank, setBank] = useState<IApmFieldValue | undefined>();

  const isOB = obIds.includes(apm.id);
  const isBlik = apm.id === 'blik'

  // @ts-ignore
  const bankField = obBankIdName[apm.id];
  const apmFieldValues = bankField ? apm.fields.find((f: IAPMField) => f.id === bankField).listValues : [];

  useEffect(() => {
    if (paymentError) {
      trackErrorScreen('error_screen', order, t(paymentError), apm?.name)
    }
  }, [paymentError])

  const showApmView = getApmView(step, order)

  const сheckedBlikFields: IAPMField[] = []

  if (isBlik && apm?.fields?.length > 0) {
    apm.fields.forEach((field: IAPMField) => {
      if (field?.defaultValue) {
        сheckedBlikFields.push(field)
      }
    })
  }

  const getInitialValues = () => {
    if (isBlik && apm?.fields?.length > 0) {
      return {
        firstName: apm.fields[0]?.defaultValue ?? '',
        lastName: apm.fields[1]?.defaultValue ?? '',
      }
    }

    return null
  }

  return (
    <Loader loading={checkingKyc}>
      <div className={classes.root}>
        <Form
          onSubmit={(inputs) => {
            if (validateBillingAddress(validateTermsAccept(true, false)[0])) {
              const apmFields: any = {
                id: apm.id,
                inputs
              };
              if (isOB && bank && bankField) {
                apmFields.inputs[bankField] = bank.code;
              }
              setFormData({
                source: PaymentSource.APM,
                orderId: order.id,
                apm: apmFields,
              })
            }
          }}
          initialValues={getInitialValues()}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {apm?.fields?.length > 0 && (
                <div>
                  {сheckedBlikFields.length !== 2 && (
                    <div className={paymentFormClasses.blockTitle}>
                      {t('Payment Information')}
                    </div>
                  )}
                  {isOB && apmFieldValues.length && (
                    <div>
                      <AutocompleteInput
                        onChange={(v) => {
                            setBank(v ? apmFieldValues.find((f: IApmFieldValue) => f.code === v.value) : undefined);
                          }}
                          label={t('Bank')}
                          value={bank?.code}
                          options={apmFieldValues.map((v: IApmFieldValue) => ({ value: v.code, label: v.caption }))}
                        />
                        {bank && bank.mandatoryFields.map((field) => {
                          const f = apm.fields?.find((v: IAPMField) => v.id === field);
                          return (
                            <Input
                              key={f.id}
                              name={f.id}
                              label={f.name || t(f.id)}
                              validate={composeValidators(
                                required,
                                (v: string) => f.validation ? validateByRegex(v, f.validation) : undefined
                              )}
                              fullWidth
                            />
                          )
                        })}
                    </div>
                  )}
                  {(!isOB || !apmFieldValues.length) &&
                    сheckedBlikFields.length !== 2 &&
                    apm.fields.map((field: IAPMField) => {
                      return (
                        <Input
                          key={field.id}
                          name={field.id}
                          label={field.localizedName || t(field.id)}
                          validate={composeValidators(required, (v: string) =>
                            field.validation
                              ? validateByRegex(v, field.validation)
                              : undefined
                          )}
                          fullWidth
                        />
                      )
                    })}
                </div>
              )}
              <OrderDetails order={order} styles={paymentFormClasses} />
              {(!hasApplePay || !hasGooglePay)  && (
                <AddressAndTerms
                  customer={customer}
                  setConfirmFormChange={setConfirmFormChange}
                  addressAcceptField={addressAcceptField}
                  termsError={termsError}
                  setTermsError={setTermsError}
                  setTermsAction={setTermsAction}
                  termsAcceptField={termsAcceptField}
                />
              )}
              <div className={paymentFormClasses.payButtonWrapper}>
                <PayButton
                  onClick={() =>
                    trackClickPay(
                      'click_pay',
                      order,
                      apm?.name,
                      'Payment method'
                    )
                  }
                  disabled={buttonDisabled || (isOB && !bank)}
                  requestInProgress={requestInProgress}
                  amount={apmAmount || order.paymentAmount}
                  currency={order.paymentCurrency}
                  showApmView={showApmView}
                  apm={order?.apm}
                />
              </div>
            </form>
          )}
        />
      </div>
    </Loader>
  )
}
