import { PrivateApi, usePrivateFetchApi } from '../index';
import useUpdateApi from '../../hooks/useUpdate';

export enum OrderType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum OrderExtendedType {
  FIAT_TO_CRYPTO = 'FIAT_TO_CRYPTO',
  CRYPTO_TO_WALLET = 'CRYPTO_TO_WALLET',
  FIAT_TO_WALLET = 'FIAT_TO_WALLET',
  WALLET_TO_CRYPTO = 'WALLET_TO_CRYPTO',
  WALLET_TO_FIAT = 'WALLET_TO_FIAT',
  CRYPTO_TO_FIAT = 'CRYPTO_TO_FIAT',
  INVOICE = 'INVOICE',
}

export function useCustomerOrders() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/order/customer/list', ...data),
    { items: [], cursor: 0 }
  );
}

export function useMerchantOrders() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/order/merchant/list', ...data),
    { items: [], cursor: 0 }
  );
}

export function useMerchantSidsList() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/apikey/list', ...data),
    { items: [], cursor: 0 }
  )
}

export function useMerchantAPMList() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/apm/list', ...data),
    { items: [], cursor: 0 }
  )
}

export function useOrderConvert(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/tools/convert', ...data),
    cb
  )
}

export function useAPMConvert(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/tools/apm/amount', ...data),
    cb
  )
}

export function useOrderInit(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/order/init', ...data),
    cb
  )
}

export function useWithdrawalAccept(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/order/accept', ...data),
    cb
  )
}

export function useWithdrawalCodeResend(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/order/withdrawal/code', ...data),
    cb
  )
}

export function useOrderCancel(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/order/cancel', ...data),
    cb
  )
}
