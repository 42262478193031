import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import captchaRequest from '@/helpers/captcha'
import KycRejected from '../Exchange/forms/KycRejected'
import PaymentForm from '../Exchange/forms/Pay'
import {
  useAcceptOrder,
  OrderStatus,
  OrderProcessingError,
  IOrder,
} from '../../api/order'
import useFormStyles from '../Exchange/forms/useFormStyles'
import { ICurrency } from '@/types/currency'
import ResidenceRestricted from '../Account/Profile/ResidenceRestricted'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  progress: {
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': 4,
    'width': '100%',
    'display': 'flex',
    '& .step': {
      'background': 'rgba(0, 0, 0, 0.12)',
      'marginRight': 2,
      'flex': '1 1 auto',
      'transition': 'background 300ms ease-in 0s',
      '&:last-child': {
        marginRight: 0,
      },
      '&.active': {
        background: theme.palette.primary.main,
      },
    },
  },
  merchantLogo: {
    height: 42,
    [theme.breakpoints.down(400)]: {
      height: 36,
    },
  },
}))

interface IProps {
  token: string
  createNewOrder: any
  order: any
  setOrder(order: IOrder): void
  loadOrder: any
  orderError?: any
  ui: any
  setProcessingError(error: OrderProcessingError): void

  confirmFormChange?: boolean
  setConfirmFormChange?(v: boolean): void
  setCreateNewOrder?(v: boolean): void
  currencies: ICurrency[];
}

export default function Pay({
  token,
  order,
  setOrder,
  orderError,
  loadOrder,
  ui,
  confirmFormChange,
  setConfirmFormChange,
  createNewOrder,
  setProcessingError,
  currencies,
}: IProps) {
  const { id } = useParams()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const formClasses = useFormStyles()
  const [serverError, setServerError] = useState('')
  const [customer, setCustomer] = useState({
    kycStatus: null,
    kycFlow: null,
  } as any)
  const {
    global: { logo },
  } = ui
  const lsErr = window.localStorage.getItem('pe')
  let resErr: any
  if (lsErr) {
    const parsed = JSON.parse(lsErr)
    if (parsed.oid === id) {
      resErr = parsed.e
    }
  }

  const {
    accept,
    accepting,
    limitError,
    error: acceptError,
    kycRejected,
    countryNotSupported,
  } = useAcceptOrder({
    order,
    customer,
    setOrder,
    setCustomer,
    loadOrder,
    navigateOnSuccess: (status) =>
      history.push(
        `/order/${id}/${
          status === OrderStatus.VERIFICATION ? 'verification' : 'card'
        }${window.location.search}`
      ),
    setProcessingError,
  })

  useEffect(() => {
    if (order.id && resErr === undefined) {
      captchaRequest(
        '/api/widget/v1/order/accept',
        accept,
        {
          email: order.acceptedByEmail,
          id: order.id,
          currency: order.paymentCurrency,
          authToken: token,
        }
      )
    }
  }, [order.id])

  useEffect(() => {
    if (orderError && orderError.type) {
      if (orderError.type === 'ORDER_ALREADY_PROCESSED') {
        return setProcessingError(OrderProcessingError.ORDER_ALREADY_PROCESSED)
      }
      setServerError('somethingWentWrong')
    }
  }, [orderError])

  useEffect(() => {
    setServerError(acceptError)
  }, [acceptError])

  const merchantName = order.legal ? order.legal.name : ''

  if (kycRejected) {
    return (
      <div className={classes.root}>
        <div className={classes.progress}>
          <div className="step active" />
          <div className="step" />
          <div className="step" />
        </div>
        <KycRejected classes={formClasses} t={t} order={order} type="kyc" />
      </div>
    )
  }

  if (countryNotSupported) {
    return <ResidenceRestricted country={countryNotSupported} order={order} />
  }

  return (
    <PaymentForm
      order={order}
      ui={ui}
      setCustomer={setCustomer}
      customer={customer}
      loading={accepting}
      limitAcceptError={limitError}
      acceptError={serverError}
      withToken
      setConfirmFormChange={setConfirmFormChange}
      confirmFormChange={confirmFormChange}
      setCreateNewOrder={createNewOrder}
      setProcessingError={setProcessingError}
      currencies={currencies}
    />
  )
}
