import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ILimitError } from '../components/TxLimitsError'
import { getLimitPeriod, isTxLimitError } from '../helpers/limitError'
import Api from './index'
import useFetchApi from '../hooks/useFetch'
import useUpdateApi from '../hooks/useUpdate'
import { OrderExtendedType } from './private/orders'
import { setKycStatus } from '../store/actions/routing'

export enum OrderStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  PROCESSING = 'PROCESSING',
  EXECUTED = 'EXECUTED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  VERIFICATION = 'VERIFICATION',
  SUSPICIOUS = 'SUSPICIOUS',
}

export interface INftOrderData {
  id: string
}

interface IAPMData {
  id: null | string
  inputs: null | string
  lastId: null | string
}

export interface IOrder {
  id: string
  amount: any
  currency: string
  address: string
  unDomain?: string
  paymentCurrency?: string
  paymentAmount?: any
  status: OrderStatus
  acceptedById?: string
  acceptedByEmail?: string
  acceptedByPhone?: string
  paymentCurrencyLocked?: boolean
  createdAt: number
  bonus?: number
  apmData?: IAPMData
  sendAmount?: number
  externalId?: string
  postbackStatus?: string
  type?: OrderExtendedType
  url?: string
  successUrl?: string
  failUrl?: string
  legal: {
    name: string
  }
  hasCard?: boolean
  fo?: boolean
  blockchainTxId?: string
  finalPaymentAmount?: string
  flow: string
  mId?: string
  nft?: INftOrderData
  nftContract?: string
  apm?: string
  sf?: boolean
}

export const emptyOrder: IOrder = {
  id: '',
  amount: 0,
  currency: '',
  address: '',
  paymentCurrency: '',
  paymentAmount: 0,
  status: OrderStatus.NEW,
  acceptedByEmail: '',
  externalId: '',
  createdAt: Date.now(),
  legal: { name: 'Utorg' },
  flow: '',
}

export enum OrderProcessingError {
  ORDER_ALREADY_PROCESSED = 'ORDER_ALREADY_PROCESSED',
  SUSPICIOUS_ORDER = 'SUSPICIOUS_ORDER',
}

export interface IOrderAcceptReq {
  id: string
  email: string
  currency?: string
  address?: string
  authToken?: string
}

export function useOrder() {
  return useFetchApi(
    async (...data: any) => Api.post('/order/view', ...data),
    { data: {} },
    undefined,
    true
  )
}

export function useOrderCardData() {
  const [cardData, cardDataLoading, loadCardData] = useFetchApi(
    async (...data: any) => Api.post('/order/card', ...data),
    { data: {} },
    undefined,
    false
  )

  return [cardData, cardDataLoading, loadCardData]
}

export function useOrderInfo() {
  const [dataP, ...other] = useFetchApi(
    async (...data: any) => {
      return Api.post('/order/view', ...data)
    },
    { data: {} },
    undefined,
    undefined,
    ['ORDER_ALREADY_PROCESSED']
  )
  const remaped = useMemo(() => dataP, [dataP])
  return [remaped, ...other]
}

export function useAccept(cb?: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/order/accept', ...data),
    cb,
    [
      'ORDER_ALREADY_PROCESSED',
      'NOT_FOUND',
      'CONVERSION_RATE_CHANGED',
      'KYC_NOT_APPLIED',
    ]
  )
}

interface IAcceptOrderParams {
  setCustomer(data: any): void
  setOrder(data: IOrder): void
  loadOrder?(data: any): void
  setConfirm?(confirm: boolean): void
  order: IOrder
  customer: any
  authToken?: string
  navigateOnSuccess?(status: OrderStatus, id: number): void
  setProcessingError(error: OrderProcessingError): void
}

interface IAcceptOrder {
  accept(data: any): void
  accepting: boolean
  error: string
  errorType: string
  limitError: ILimitError
  kycRejected: boolean
  countryNotSupported: string
}

export function useAcceptOrder({
  setOrder,
  setCustomer,
  setConfirm,
  order,
  customer,
  loadOrder,
  authToken,
  navigateOnSuccess,
  setProcessingError,
}: IAcceptOrderParams): IAcceptOrder {
  const dispatch = useDispatch()
  const [errorType, setErrorType] = useState('')
  const [error, setError] = useState('')
  const [limitError, setLimitError] = useState<ILimitError>({
    error: '',
    limit: '',
    used: '',
  })
  const [kycRejected, setKycRejected] = useState(false)
  const [countryNotSupported, setCountryNotSupported] = useState('')
  const [{ data: orderInfo }, loadingOrderInfo, loadOrderInfo] = useOrder()

  const [accept, accepting] = useAccept((res: any) => {
    if (res.success === true) {
      setCustomer({
        ...customer,
        ...res.data.customer,
        kycFlow: res.data.order.kycFlow,
      })

      const newOrder = {
        ...order,
        ...res.data.order,
        status: OrderStatus.ACCEPTED,
      }

      setOrder(newOrder)
      loadOrderInfo({ id: newOrder.id }).then(setOrder)
      setError('')
      dispatch(setKycStatus(res.data.customer.kycStatus))

      if (setConfirm) setConfirm(true)
      if (navigateOnSuccess)
        navigateOnSuccess(res.data.order.status, res.data.order.id)
    } else if (res.error) {
      const { type } = res.error
      if (type === 'CONVERSION_RATE_CHANGED') {
        setErrorType('alert')
        setError(type)
        return (
          loadOrder &&
          loadOrder({ id: order.id, currency: order.paymentCurrency })
        )
      }
      if (type === 'KYC_NOT_APPLIED' && setKycRejected) {
        return setKycRejected(true)
      }
      if (type === 'COUNTRY_NOT_SUPPORTED' && setCountryNotSupported) {
        return setCountryNotSupported(res?.error?.message)
      }
      setErrorType('error')
      if (type === 'ORDER_ALREADY_PROCESSED' || type === 'NOT_FOUND') {
        return setProcessingError(OrderProcessingError.ORDER_ALREADY_PROCESSED)
      }
      if (isTxLimitError(type)) {
        return setLimitError({
          error: `${type}_${getLimitPeriod(res.error.details[0].type)}`,
          limit: res.error.details[0].limitAmount
            ? parseFloat(res.error.details[0].limitAmount).toFixed(2)
            : res.error.details[0].limit,
          used: parseFloat(res.error.details[0].usedAmount).toFixed(2),
        })
      }
      if (authToken) {
        window.location.search = ''
      }
      console.log(type)
      setError('somethingWentWrong')
    }
  })

  return {
    accept: [
      OrderStatus.ERROR,
      OrderStatus.EXECUTED,
      OrderStatus.SUCCESS,
      OrderStatus.SUSPICIOUS,
      OrderStatus.VERIFICATION
    ].includes(order.status) ? () => null : accept,
    accepting,
    error,
    errorType,
    limitError,
    kycRejected,
    countryNotSupported,
  }
}
