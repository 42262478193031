import { PrivateApi, usePrivateFetchApi } from '../index'
import useUpdateApi from '../../hooks/useUpdate'

export function useBalances() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/account/balances', ...data),
    { items: [] }
  )
}

export function useSetResidence(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/account/country', ...data),
    cb
  )
}

export function useAcceptTerms(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/account/terms', ...data),
    cb
  )
}

export function useReferralBonusList() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/account/referral/bonus', ...data),
    { items: [] }
  )
}

export function useSettlementBalancesList() {
  return usePrivateFetchApi(
    async (...data: any) =>
      PrivateApi.post('/settlement/balances/list', ...data),
    { items: [] }
  )
}

export function useSettlementsList() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/settlement/find', ...data),
    { items: [] }
  )
}

export function useSettlementInit(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/settlement/init', ...data),
    cb
  )
}
