import React from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
// import AddIcon from '@material-ui/icons/Add';
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { getButtonText, isChainValley, isUtpay } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-end',
    'width': 400,
    'marginRight': 50,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
    },
    '& > *': {
      marginRight: theme.spacing(1),
      height: 36,
    },
  },
  button: {
    marginRight: 50,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}))

interface IProps {
  closeMenu?: any
  balance?: number
}

const env = process.env.REACT_APP_WIDGET_ENV

const getButtonWidth = (lang: string) => {
  const lng = lang.split('-')[0]
  let width = '160px'
  switch (lng) {
    case 'ru':
      width = '260px'
      break
    case 'fr':
      width = '188px'
      break
  }
  return width
}

export default function OperationsButtons({ closeMenu, balance }: IProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { user } = useSelector((state: any) => state.account)

  // ZHPW-1159 TMP remove deposit/withdrawal for b2c
  // ZHPW-1182 TMP show deposit button on dev env
  return (
    <div className={classes.root}>
      {env === 'dev' && (
        <Button
          variant="contained"
          component={NavLink}
          to={`/account/orders/create/deposit`}
          className={globalClasses.button}
          onClick={closeMenu}
          color="primary"
          disabled={isChainValley}
          style={{ flex: isChainValley ? '0 0 195px' : '0 0 160px' }}
        >
          {t(getButtonText(MIRROR))}
        </Button>
      )}
      {!user.authorities ||
      (!user.authorities.includes('ROLE_MERCHANT') &&
        !isUtpay &&
        !isChainValley) ? (
        <Button
          variant="contained"
          component="a"
          href={`https://${
            !env || env === 'prod' ? '' : `landing-${env}.`
          }utorg.pro`}
          className={clsx(globalClasses.button, classes.button)}
          color="primary"
          style={{ flex: `0 0 ${getButtonWidth(language.toLowerCase())}` }}
        >
          {t('account-DEPOSIT')}
        </Button>
      ) : (
        !!balance && (
          <Button
            variant="contained"
            component={NavLink}
            to={`/account/orders/create/withdrawal`}
            className={globalClasses.button}
            onClick={closeMenu}
            color="primary"
            style={{ flex: '0 0 160px' }}
          >
            {t('account-WITHDRAWAL')}
          </Button>
        )
      )}
    </div>
  )

  // return (
  //   <div className={classes.root}>
  //     {(!user.authorities || !user.authorities.includes('ROLE_MERCHANT')) && (
  //       <Button
  //         variant="contained"
  //         component={NavLink}
  //         to={`/account/orders/create/deposit`}
  //         className={globalClasses.button}
  //         onClick={closeMenu}
  //         color="primary"
  //         startIcon={<AddIcon />}
  //         style={{ flex: `0 0 ${language.toLowerCase().includes('ru') ? 260 : 160}px` }}
  //       >
  //         {t('account-DEPOSIT')}
  //       </Button>
  //     )}
  //     {!!balance && (
  //       <Button
  //         variant="contained"
  //         component={NavLink}
  //         to={`/account/orders/create/withdrawal`}
  //         className={globalClasses.button}
  //         onClick={closeMenu}
  //         color="primary"
  //       >
  //         {t('account-WITHDRAWAL')}
  //       </Button>
  //     )}
  //   </div>
  // );
}
