import { KycStatus } from '@/api/kyc'
import { IOrder, OrderStatus, useOrder } from '@/api/order'
import { OrderExtendedType } from '@/api/private/orders'
import NFTPreview from '@/components/NFTPreview'

import ButtonCheckTwitter from '@/components/Promo/ButtonCheckTwitter'
import { qaAttr } from '@/helpers/qaHelpers'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import Button from '@material-ui/core/Button'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import OrderDetails from './Details'
import {
  DocumentsVerification,
  FinalScreenIcon,
  ErrorFinal,
  SuccessPayout,
} from './FinalScreen'
import usePaymentFormStyles from './usePaymentFormStyles'
import { IAPM } from '@/api/settings'
import { trackPaymentResult } from '@/helpers/analytics'
import PayoutDetailsError from '../Payout/components/PayoutDetailsError'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow'

interface IProps {
  step: string
  nextStep: any
  order: IOrder
  customer: any
  logo?: string
  error?: string
  apm?: IAPM
}

export default function PayFinish({
  step,
  nextStep,
  customer,
  order,
  error,
  apm,
}: IProps) {
  const timeoutRef = useRef<any>({ ref: null })

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const paymentFormClasses = usePaymentFormStyles()
  const { t } = useTranslation()
  const [{ data: orderInfoAny }, loading, load] = useOrder()
  const { uiLoaded, ui } = useSelector((state: any) => state.common)

  const twitterLink = uiLoaded && ui?.promo?.twitterLink

  const isSuccess = ['success', 'pending'].includes(step)
  const isUnavailableApm = step === 'unavailable-apm'
  const isError = step === 'error'
  const isPayout = order.type === OrderExtendedType.WALLET_TO_FIAT
  const isNFTFlow = defineNFTFlow(order?.currency)

  const orderInfo = orderInfoAny as IOrder
  useEffect(() => {
    window.scrollTo(0, 0)
    load({ id: order.id })
  }, [order.id])

  useEffect(() => {
    if (step !== 'pending') {
      return
    }
    timeoutRef.current.ref = setInterval(() => {
      load({ id: order.id })
    }, 5000)

    return () => {
      clearInterval(timeoutRef.current.ref)
    }
  }, [step])

  useEffect(() => {
    if (
      step === 'pending' &&
      [
        OrderStatus.SUSPICIOUS,
        OrderStatus.EXECUTED,
        OrderStatus.SUCCESS,
      ].includes(orderInfo.status)
    ) {
      nextStep('success')
      return
    }

    if (orderInfo.status === OrderStatus.ERROR) {
      nextStep('error')
      return
    }
  }, [orderInfo])

  useEffect(() => {
    if (
      !isError &&
      !isUnavailableApm &&
      !isPayout &&
      ![
        KycStatus.NEW,
        KycStatus.WAITING_FOR_APPROVAL,
        KycStatus.PENDING,
      ].includes(customer.kycStatus)
    ) {
      trackPaymentResult(
        step === 'pending' ? 'payment_pending' : 'payment_success',
        order,
        apm?.name,
        customer?.level
      )
      if (step !== 'pending') {
        trackPaymentResult(
          'view_order_success',
          order,
          apm?.name,
          customer?.level
        )
      }
    }
  }, [step, customer.kycStatus, isUnavailableApm, isPayout])

  if (!customer.kycStatus ||
    ![
      KycStatus.NEW,
      KycStatus.WAITING_FOR_APPROVAL,
      KycStatus.PENDING,
    ].includes(customer.kycStatus) ||
    isUnavailableApm) {
    return (
      <div className={classes.root}>
        <div className={classes.result}>
          <FinalScreenIcon step={step} />
        </div>
        {!isNFTFlow && (
          <div className={classes.result}>
            {loading ? (
              <Skeleton variant="rect" width={180} />
            ) : (
              <Typography
                variant="h4"
                component="span"
                color="textPrimary"
                translate="no"
                {...qaAttr('final-amount-title')}
              >
                {isPayout
                  ? `${order.amount} ${order.currency}`
                  : `${
                      orderInfo?.finalPaymentAmount || orderInfo?.paymentAmount
                    } ${order.paymentCurrency}`}
              </Typography>
            )}
          </div>
        )}
        {(isError || isUnavailableApm) && (
          <ErrorFinal
            customer={customer}
            apm={apm}
            order={order}
            isUnavailableApm={isUnavailableApm}
            error={error || (isPayout ? 'PROVIDER_ERROR' : undefined)}
            isPayout={isPayout}
          />
        )}
        {!isError && !isUnavailableApm && !isPayout && (
          <div className={classes.result}>
            {isSuccess &&
            customer.kycStatus &&
            [
              KycStatus.NEW,
              KycStatus.WAITING_FOR_APPROVAL,
              KycStatus.PENDING,
            ].includes(customer.kycStatus) ? (
              <Typography
                variant="h5"
                align="center"
                component="div"
                color="textPrimary"
              >
                {t('paymentResult.amountPreAuthorized')}
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h5"
                  align="center"
                  component="div"
                  color="textPrimary"
                >
                  {t(
                    step === 'pending'
                      ? 'paymentResult.pendingTitle'
                      : 'paymentResult.successTitle'
                  )}
                </Typography>
                {isNFTFlow && (
                  <Typography
                    className={classes.subtitle}
                    variant="body2"
                    align="center"
                    component="div"
                    color="textSecondary"
                  >
                    {t('paymentResult.successNFTSubtitle')}
                  </Typography>
                )}
              </>
            )}
          </div>
        )}
        {isPayout && !isError && (
          <SuccessPayout order={order} styles={paymentFormClasses} />
        )}
        {isSuccess &&
        customer.kycStatus &&
        [
          KycStatus.NEW,
          KycStatus.WAITING_FOR_APPROVAL,
          KycStatus.PENDING,
        ].includes(customer.kycStatus) && <div />}
        {isSuccess &&
        !isPayout &&
        !loading &&
        orderInfo &&
        orderInfo.status !== OrderStatus.EXECUTED &&
        orderInfo.status !== OrderStatus.SUCCESS &&
        customer.kycStatus &&
        ![
          KycStatus.TRUSTED,
          KycStatus.APPROVED,
          KycStatus.NEW,
          KycStatus.WAITING_FOR_APPROVAL,
          KycStatus.PENDING,
          KycStatus.REVIEWED,
        ].includes(customer.kycStatus) && !orderInfo.sf && (
          <Typography
            variant="body2"
            align="center"
            component="div"
            className={classes.resultKyc}
          >
            <p>
              {t('verificationStatus')}{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: t(`kycStatusData.${customer.kycStatus}`),
                }}
              />
            </p>
            <p>{t('paymentResult.updatesWillSentToEmail')}</p>
          </Typography>
        )}
        {!isPayout && <div className={paymentFormClasses.delim} />}
        {order.nft && order.nftContract && !isNFTFlow && (
          <>
            <NFTPreview
              id={Number(order.nft.id).toString()}
              contract={order.nftContract}
            />
            <div className={paymentFormClasses.delim} />
          </>
        )}

        {!isPayout && (
          <OrderDetails order={orderInfo} styles={paymentFormClasses} />
        )}
        {isPayout && isError && (
          <PayoutDetailsError order={order} styles={paymentFormClasses} />
        )}
        {isSuccess && twitterLink && (
          <div className={classes.button}>
            <ButtonCheckTwitter href={twitterLink} />
          </div>
        )}
        {isSuccess && order.successUrl && (
          <div className={classes.button}>
            <Typography variant="body2" align="center" style={{ marginTop: 16 }}>
              <Button
                className={globalClasses.button}
                href={order.successUrl}
                variant="contained"
                color="primary"
                {...qaAttr('final-success-back-btn')}
              >
                {t(isPayout ? 'payout.successButton' : 'successBack')}
              </Button>
            </Typography>
          </div>
        )}
        {!isSuccess && order.failUrl && (
          <div className={classes.button}>
            <Typography variant="body2" align="center" style={{ marginTop: 16 }}>
              <Button
                className={globalClasses.button}
                href={order.failUrl}
                variant="contained"
                color="primary"
              >
                {t(isUnavailableApm ? 'unavailableApm.button' : 'failBack')}
              </Button>
            </Typography>
          </div>
        )}
      </div>
    )
  }

  return <DocumentsVerification order={orderInfo} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    color: 'rgba(0,0,0,0.6)',
  },
  result: {
    'textAlign': 'center',
    'marginBottom': theme.spacing(1),
    '& .MuiSkeleton-root': {
      margin: '0 auto',
      height: 34,
      [theme.breakpoints.down('xs')]: {
        height: 26,
      },
    },
  },
  logo: {
    'textAlign': 'center',
    'marginTop': theme.spacing(1),
    'marginBottom': theme.spacing(2),
    '& img': {
      maxHeight: 30,
    },
  },
  delim: {
    margin: '10px -40px',
    height: 1,
    background: theme.palette.divider,
  },
  resultKyc: {
    background: '#fefaed',
    margin: '0 -40px',
    padding: '26px 40px',
  },
  button: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    paddingTop: theme.spacing(2),
  },
}))
