import axios from 'axios'
import addHours from 'date-fns/addHours'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import cookies from 'react-cookies'
import { setLogIn, setBlockedUser } from '../store/actions/account'
import {
  setMaintenance,
  setCloudflareLimitError,
  setCustomerNotSupported,
} from '../store/actions/common'
import isCFError from '../helpers/limitError'
import * as Sentry from '@sentry/react'

interface IAuthHeader {
  'X-AUTH-TOKEN': string
}

type TToken = IAuthHeader | undefined

export function getAuthToken(): TToken {
  const saved = window.localStorage.getItem('xat')
  if (saved) {
    const [token, expire] = atob(saved).split(';')
    if (parseInt(expire) < Date.now()) {
      clearAuthToken()
      return
    }
    return { 'X-AUTH-TOKEN': token }
  }
}

export function setAuthToken(token: string) {
  const data = btoa(`${token};${addHours(new Date(), 24).valueOf()}`)
  window.localStorage.setItem('xat', data)
  cookies.remove('xat', { domain: 'utpay.io' })
  cookies.save('xat', data, { domain: 'utpay.io', path: '/' })
}

export function clearAuthToken() {
  window.localStorage.removeItem('xat')
  cookies.remove('xat', { domain: 'utpay.io' })
}

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4530/api'

const Api = axios.create({
  withCredentials: !!process.env.REACT_APP_API_URL,
  baseURL: `${apiUrl}/widget/v1`,
})

export const PrivateApi = axios.create({
  withCredentials: !!process.env.REACT_APP_API_URL,
  baseURL: `${apiUrl}/private/v1`,
})

export const MerchantApi = axios.create({
  baseURL: `${apiUrl}/merchant/v1`,
})

interface IData {
  items?: any
  full: boolean
  data?: any
  error?: any
  left?: number
  cursor?: number
}

const getErrFromResponse = (e: any) =>
  e.response.data && e.response.data.error
    ? e.response.data.error.message || e.response.data.error.type
    : 'somethingWentWrong'

export function usePrivateFetchApi(
  remote: any,
  initialData: any,
  dispatchAction?: any,
  notFoundErr = 'Not found',
  onErrorData?: any,
  sentryIgnoreErrors: string[] = []
): [IData, boolean, (...args: any) => void] {
  const [data, setData] = useState({ ...initialData, error: null, full: false })
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const load = async (args: any) => {
    setLoading(true)
    let res = initialData

    try {
      res = await remote(args || {}, { headers: { ...getAuthToken() } })
      dispatch(setBlockedUser(true))
    } catch (e) {
      let err = e.toString()
      if (e.response) {
        err = getErrFromResponse(e)
        if (
          !e.response.data?.error &&
          e.response.status.toString().startsWith('5')
        ) {
          dispatch(setMaintenance(true))
        }
        if (e.response?.data?.error?.type === 'CUSTOMER_NOT_SUPPORTED') {
          dispatch(setCustomerNotSupported(true))
        }
        if (e.response.data && e.response.data.error) {
          if (e.response.status.toString() === '429' && isCFError(e.response)) {
            dispatch(setCloudflareLimitError())
          }
          const { error } = e.response.data
          // if (error.type === 'ACCESS_DENIED') {
          //   dispatch(setBlockedUser());
          // }
          if (error.type === 'UNAUTHORIZED') {
            clearAuthToken()
            dispatch(setLogIn({}))
          }
          if (
            process.env.REACT_APP_SENTRY_URL &&
            !sentryIgnoreErrors.includes(error.type)
          ) {
            Sentry.captureException(
              new Error(
                `PRIVATE FETCH API ERROR status: ${e.response.status} url: ${e.response.config.url}`
              ),
              {
                extra: {
                  response: error,
                  request: e.response.config,
                },
                tags: {
                  component: 'api',
                  url: e.response.config.url,
                },
              }
            )
          }
          if (error.type === 'NOT_FOUND') {
            err = notFoundErr
          }
        }
      }
      let upd: any = { ...data, error: err }
      if (onErrorData) {
        upd = { ...upd, ...onErrorData }
      }
      setData(upd)
    }

    if (res.headers && res.headers['x-auth-token']) {
      if (res.headers['x-auth-token'] === '-') {
        clearAuthToken()
        dispatch(setLogIn({}))
      } else {
        setAuthToken(res.headers['x-auth-token'])
      }
    }

    if (res.data) {
      if (res.data.error) {
        setData({ ...data, error: res.data.error })
      } else {
        if (args?.export) {
          setData({ data: res.data, full: true })
        } else {
          const full =
            (res.data.data && res.data.data.length < 20) ||
            res.data.hasNext === false
          setData({
            ...data,
            ...res.data,
            error: null,
            items:
              res.data.data && data.items
                ? args && typeof args === 'object' && args.cursor
                  ? data.items.concat(res.data.data)
                  : [].concat(
                      Array.isArray(res.data.data)
                        ? res.data.data
                        : [res.data.data]
                    )
                : [],
            full,
            cursor: res.data && res.data.cursor ? res.data.cursor : 0,
          })
          if (typeof dispatchAction === 'function') {
            dispatch(dispatchAction(res.data.data))
          } else if (Array.isArray(dispatchAction)) {
            dispatchAction.forEach((item: any) => {
              if (typeof item === 'function') {
                dispatch(item(res.data.data))
              }
            })
          }
        }
      }
    }
    setLoading(false)
  }

  return [data, loading, load]
}

export default Api
