import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Waypoint } from 'react-waypoint';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import Typography from '@material-ui/core/Typography';
import Loader from '../../../components/Loader';
import TXList from './TxList';
import TxMobileList from './TxMobileList';
import OrdersFilter from './OrdersFilter';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import { useMerchantOrders } from '../../../api/private/orders'
import useFilter from '../../../hooks/useFilter';
import TXMobileList from './TxMobileList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays'

export const emptyFilter = {
  dateFrom: startOfDay(subDays(new Date(), 6)).valueOf(),
  dateTo: endOfDay(new Date()).valueOf(),
  status: null,
  paymentCurrency: null,
  acceptedById: null,
  acceptedBy: null,
  sid: null,
  paymentMethod: null,
  orderId: null,
  externalId: null,
  orderTypes: null,
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  title: {
    flex: 1,
    position: 'absolute',
    marginTop: -theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  filterWrapperDesktop: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  filterWrapper: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
  },
  ordersFilter: {
    '& button': {
      top: 0,
      right: 6,
      padding: 6,
    },
    '& .react-datepicker__navigation': {
      padding: 0,
      top: 10,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dialogWrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 480
    },
    '& .MuiDialogContent-root': {
      textAlign: 'center'
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center'
    }
  },
  dialogButton: {
    height: 36,
    flex: `0 0 260px`,
  },
  button: {
    height: 36,
    flex: `0 0 160px`,
    [theme.breakpoints.down('xs')]: {
      flex: `0 0 140px`,
    },
  },
}))

export default function MerchantOrders() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { user } = useSelector((state: any) => state.account);
  const [{ items: orders, cursor, full, error }, loading, load] =
    useMerchantOrders()
  const [exportedData, exporting, exportData] = useMerchantOrders();
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [exportedFilename, setExportedFilename] = useState('');
  const [setFilter, filter] = useFilter('merchant-orders', emptyFilter);
  const [customerFilterError, setCustomerFilterError] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  if (user.authorities && !user.authorities.includes('ROLE_MERCHANT')) {
    history.push('/account/orders');
  }

  const exportFilteredData = (e: any) => {
    const dateFrom = format(filter.dateFrom, 'dd.MM.yyyy');
    const dateTo = format(filter.dateTo, 'dd.MM.yyyy');

    setExportedFilename(`orders-${dateFrom}-${dateTo}`);
    exportData({
      filter,
      export: true
    });
  }

  useEffect(() => {
    if (exportedFilename && !exporting && exportedData) {
      setShowExportDialog(true);
    }
  }, [exportedData, exportedFilename, exporting]);

  const onCancel = () => {
    setTimeout(() => {
      setShowExportDialog(false);
      setExportedFilename('');
    }, 100);
  }

  //@ts-ignore
  const href = URL.createObjectURL(new Blob([exportedData?.data ? exportedData.data : ''], { type: 'text/csv' }));

  return (
    <div>
      <Dialog classes={{root: classes.dialogWrapper}} open={showExportDialog} onClose={onCancel} fullWidth>
        <div style={{width: '100%', padding: '12px 16px'}}>
          <DialogContent>{t('Report was generated')}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              component="a"
              download={`${exportedFilename}.csv`}
              href={href}
              className={clsx(globalClasses.button, classes.dialogButton)}
              color="primary"
              onClick={onCancel}
            >
              {t('Download report')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <div className={classes.row}>
        <Typography variant="h4" className={clsx(globalClasses.title, classes.title, 'desktop')}>{t('Clients orders')}</Typography>
        <div className={classes.filterWrapperDesktop}>
          <OrdersFilter
            searchField
            filter={filter}
            onChange={setFilter}
            onSubmit={() => load({ filter })}
            onClear={() => {
              setFilter(emptyFilter);
              load({ filter: emptyFilter });
            }}
            type="merchant"
            load={load}
            className={classes.ordersFilter}
          />
        </div>
        <Button
          disabled={exporting}
          variant="contained"
          onClick={exportFilteredData}
          className={clsx(globalClasses.button, classes.button)}
          color="primary"
        >
          {t('Export')}
        </Button>
      </div>
      <div className={classes.row}>
        <div className={classes.filterWrapper}>
          <OrdersFilter
            searchField
            filter={filter}
            onChange={setFilter}
            onSubmit={() => load({ filter })}
            onClear={() => {
              setFilter(emptyFilter);
              load({ filter: emptyFilter });
            }}
            type="merchant"
            load={load}
            className={classes.ordersFilter}
          />
        </div>
      </div>
      <div className={globalClasses.desktopShow}>
        <TXList orders={orders} loading={loading} type="merchant" />
      </div>
      <div className={globalClasses.mobileShow}>
        <TXMobileList orders={orders} loading={loading} type="merchant" />
      </div>
      <Loader loading={loading} height={orders.length > 0 ? 100 : undefined} />
    {(!loading && !error && !full) && <Waypoint onEnter={() => load({ filter, cursor, limit: 30 })} /> }
    </div>
  );
}
