import makeStyles from '@material-ui/core/styles/makeStyles'

import { getButtonApmBorderRadius } from '@/helpers/mirrorHelper'

interface IStylesProps {
  apm: string
}

type IApm = {
  background: string
  hover: string
}

const APMS_LIST: Record<string, IApm> = {
  WLT: {
    background: '#862165',
    hover: '#751b58',
  },
  NTL: {
    background: '#76BA0E',
    hover: '#64941c',
  },
  OBT: {
    background: '#66C18F',
    hover: '#5cad80',
  },
  MUB: {
    background: '#246BB5',
    hover: '#1f5ea0',
  },
  SPX: {
    background: '#32BCAD',
    hover: '#2ca598',
  },
  SPI: {
    background: '#323184',
    hover: '#2a2a6f',
  },
  BLK_WLT: {
    background: '#343434',
    hover: '#232323',
  },
  blik: {
    background: '#343434',
    hover: '#232323',
  },
  BLIK: {
    background: '#343434',
    hover: '#232323',
  },
  MBW: {
    background: '#D60510',
    hover: '#c2040e',
  },
  KHP: {
    background: '#8347AD',
    hover: '#703e94',
  },
  PGF: {
    background: '#FC0',
    hover: '#efbf00',
  },
  PWY_WLT: {
    background: '#E13134',
    hover: '#d03033',
  },
  MAH: {
    background: '#613DD2',
    hover: '#5435b5',
  },
  MCH_WLT: {
    background: '#015AB9',
    hover: '#004ea2',
  },
  GIR_WLT: {
    background: '#000268',
    hover: '#000257',
  },
  giropay: {
    background: '#000268',
    hover: '#000257',
  },
  GIR: {
    background: '#000268',
    hover: '#000257',
  },
  PSC: {
    background: '#3200ff',
    hover: '#2f04df',
  },
  OBI: {
    background: '#2f66e3',
    hover: '#0d47a1',
  },
  MBW_WLT: {
    background: '#D60510',
    hover: '#c2040e',
  },
  MUB_WLT: {
    background: '#246BB5',
    hover: '#1f5ea0',
  },
  EUT: {
    background: '#0f2287',
    hover: '#0d1d73',
  },
  EUT_WLT: {
    background: '#0f2287',
    hover: '#0d1d73',
  },
  IDL: {
    background: '#cd0067',
    hover: '#a80055',
  },
  ideal: {
    background: '#cd0067',
    hover: '#a80055',
  },
  IDL_WLT: {
    background: '#cd0067',
    hover: '#a80055',
  },
  BLB: {
    background: '#2f66e3',
    hover: '#0d47a1',
  },
  BLB_WLT: {
    background: '#2f66e3',
    hover: '#0d47a1',
  },
  EPS: {
    background: '#cd0067',
    hover: '#a80055',
  },
  EPS_WLT: {
    background: '#cd0067',
    hover: '#a80055',
  },
  NPY: {
    background: '#cd0067',
    hover: '#a80055',
  },
  SFT: {
    background: '#2f66e3',
    hover: '#0d47a1',
  },
  SFT_WLT: {
    background: '#2f66e3',
    hover: '#0d47a1',
  },
  EPY: {
    background: '#253982',
    hover: '#1c2c63',
  },
  EPY_WLT: {
    background: '#253982',
    hover: '#1c2c63',
  },
  PCH: {
    background: '#7887e6',
    hover: '#6071e2',
  },
  PCH_WLT: {
    background: '#7887e6',
    hover: '#6071e2',
  },
  bancontact: {
    background: '#015ab9',
    hover: '#004894',
  },
  default: {
    background: '#2F66E3',
    hover: '#0d47a1',
  },
}

const useButtonApmStyles = makeStyles(() => ({
  root: ({ apm }: IStylesProps ) => (
    apm && APMS_LIST[apm]
      ? {
        'background': APMS_LIST[apm].background,
        'borderRadius': getButtonApmBorderRadius(),
        '&:hover': {
          backgroundColor: APMS_LIST[apm].hover,
        },
        '&.Mui-disabled, &.customButton.Mui-disabled': {
          color: '#fff',
          backgroundColor: APMS_LIST[apm].background,
        },
      }
      : {
        'background': '#2F66E3',
        '&:hover': {
          backgroundColor: '#0d47a1',
        },
        '&.Mui-disabled, &.customButton.Mui-disabled': {
          color: '#fff',
          backgroundColor: '#2F66E3',
        },
      }
  ),
}))
export default useButtonApmStyles
